.toastContainerOverride {
	z-index: 10000000 !important;
}
.toastOverride {
	border-radius: 5px !important;
	box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.5) !important;
	font-family: "Roboto Mono", monospace !important;
	&.Toastify__toast--warning {
		background: rgba(241, 196, 15, 0.75) !important;
		div,
		.Toastify__close-button {
			color: #000 !important;
			font-weight: bold;
		}
	}
}

.module {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	box-sizing: border-box;
	overflow: hidden;
	pointer-events: none;
	> .moduleWrapper {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
