input {
	position: relative;
	padding: 10px;
	// border: 1px solid rgba(255, 255, 255, 0.1);
	// background-color: rgba(255, 255, 255, 0.05);
	// color: antiquewhite;
	border-radius: 5px;
	border: none;
	width: 100%;
	background-color: #1e1d23;
	color: #f5a623;
	box-shadow: inset 0 2px 2px 0.5px rgba(0, 0, 0, 0.5), 0 2px 2px 0.5px rgba(57, 60, 67, 1);
	&:disabled {
		opacity: 0.5;
		pointer-events: none;
	}
}
