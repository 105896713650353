@import url(https://fonts.googleapis.com/css?family=Roboto+Mono:400,700);
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type="search"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none; }

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */ }

html,
body {
  width: 100%;
  height: 100%; }
  html > #root,
  body > #root {
    width: 100%;
    height: 100%; }
    html > #root > .App,
    body > #root > .App {
      width: 100%;
      height: 100%; }

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */ }

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0; }

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  white-space: normal;
  /* 2 */
  *margin-left: -7px;
  /* 3 */ }

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */ }

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
  *height: 13px;
  /* 3 */
  *width: 13px;
  /* 3 */ }

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

html,
button,
input,
select,
textarea {
  color: #222; }

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

div,
input,
button,
label,
ul,
li,
span {
  box-sizing: border-box;
  font: inherit;
  font-family: "Roboto Mono", monospace; }

* {
  padding: 0 0 0 0;
  margin: 0 0 0 0; }

*:focus {
  outline: none; }

.break_all {
  word-break: break-all; }

.break_word {
  word-break: break-word; }

.keep_all {
  word-break: keep-all; }

.fade_30 {
  opacity: 0.3;
  pointer-events: none; }

.fade_50 {
  opacity: 0.5;
  pointer-events: none; }

.flex_h {
  display: flex;
  flex-direction: row; }

.flex_v {
  display: flex;
  flex-direction: column; }

.flex1 {
  flex: 1 1; }

.flex_c_c {
  align-items: center;
  justify-content: center; }

.flex_c_0 {
  align-items: center;
  justify-content: flex-start; }

.flex_c_1 {
  align-items: center;
  justify-content: flex-end; }

.flex_0_c {
  align-items: flex-start;
  justify-content: center; }

.flex_0_0 {
  align-items: flex-start;
  justify-content: flex-start; }

.flex_0_1 {
  align-items: flex-start;
  justify-content: flex-end; }

.flex_1_c {
  align-items: flex-end;
  justify-content: center; }

.flex_1_0 {
  align-items: flex-end;
  justify-content: flex-start; }

.flex_1_1 {
  align-items: flex-end;
  justify-content: flex-end; }

.flex_c_b {
  align-items: center;
  justify-content: space-between; }

.flex_c_a {
  align-items: center;
  justify-content: space-around; }

.flex_c_1_i {
  align-items: center !important;
  justify-content: flex-end !important; }

.flex_wrap {
  flex-wrap: wrap; }

.flex_a_0 {
  align-self: flex-start; }

.flex_a_1 {
  align-self: flex-end; }

.grid {
  display: grid; }

.l {
  text-align: left; }

.c {
  text-align: center; }

.r {
  text-align: right; }

h1,
h2,
h3,
h4,
h5 {
  padding: 0 0;
  margin: 0 0;
  text-transform: uppercase; }

.f5 {
  font-size: 5px; }

.m5_all {
  margin: 5px; }

.m5_l {
  margin-left: 5px; }

.m5_r {
  margin-right: 5px; }

.m5_t {
  margin-top: 5px; }

.m5_b {
  margin-bottom: 5px; }

.p5_all {
  padding: 5px; }

.p5_l {
  padding-left: 5px; }

.p5_r {
  padding-right: 5px; }

.p5_t {
  padding-top: 5px; }

.p5_b {
  padding-bottom: 5px; }

.f6 {
  font-size: 6px; }

.m6_all {
  margin: 6px; }

.m6_l {
  margin-left: 6px; }

.m6_r {
  margin-right: 6px; }

.m6_t {
  margin-top: 6px; }

.m6_b {
  margin-bottom: 6px; }

.p6_all {
  padding: 6px; }

.p6_l {
  padding-left: 6px; }

.p6_r {
  padding-right: 6px; }

.p6_t {
  padding-top: 6px; }

.p6_b {
  padding-bottom: 6px; }

.f7 {
  font-size: 7px; }

.m7_all {
  margin: 7px; }

.m7_l {
  margin-left: 7px; }

.m7_r {
  margin-right: 7px; }

.m7_t {
  margin-top: 7px; }

.m7_b {
  margin-bottom: 7px; }

.p7_all {
  padding: 7px; }

.p7_l {
  padding-left: 7px; }

.p7_r {
  padding-right: 7px; }

.p7_t {
  padding-top: 7px; }

.p7_b {
  padding-bottom: 7px; }

.f8 {
  font-size: 8px; }

.m8_all {
  margin: 8px; }

.m8_l {
  margin-left: 8px; }

.m8_r {
  margin-right: 8px; }

.m8_t {
  margin-top: 8px; }

.m8_b {
  margin-bottom: 8px; }

.p8_all {
  padding: 8px; }

.p8_l {
  padding-left: 8px; }

.p8_r {
  padding-right: 8px; }

.p8_t {
  padding-top: 8px; }

.p8_b {
  padding-bottom: 8px; }

.f9 {
  font-size: 9px; }

.m9_all {
  margin: 9px; }

.m9_l {
  margin-left: 9px; }

.m9_r {
  margin-right: 9px; }

.m9_t {
  margin-top: 9px; }

.m9_b {
  margin-bottom: 9px; }

.p9_all {
  padding: 9px; }

.p9_l {
  padding-left: 9px; }

.p9_r {
  padding-right: 9px; }

.p9_t {
  padding-top: 9px; }

.p9_b {
  padding-bottom: 9px; }

.f10 {
  font-size: 10px; }

.m10_all {
  margin: 10px; }

.m10_l {
  margin-left: 10px; }

.m10_r {
  margin-right: 10px; }

.m10_t {
  margin-top: 10px; }

.m10_b {
  margin-bottom: 10px; }

.p10_all {
  padding: 10px; }

.p10_l {
  padding-left: 10px; }

.p10_r {
  padding-right: 10px; }

.p10_t {
  padding-top: 10px; }

.p10_b {
  padding-bottom: 10px; }

.f11 {
  font-size: 11px; }

.m11_all {
  margin: 11px; }

.m11_l {
  margin-left: 11px; }

.m11_r {
  margin-right: 11px; }

.m11_t {
  margin-top: 11px; }

.m11_b {
  margin-bottom: 11px; }

.p11_all {
  padding: 11px; }

.p11_l {
  padding-left: 11px; }

.p11_r {
  padding-right: 11px; }

.p11_t {
  padding-top: 11px; }

.p11_b {
  padding-bottom: 11px; }

.f12 {
  font-size: 12px; }

.m12_all {
  margin: 12px; }

.m12_l {
  margin-left: 12px; }

.m12_r {
  margin-right: 12px; }

.m12_t {
  margin-top: 12px; }

.m12_b {
  margin-bottom: 12px; }

.p12_all {
  padding: 12px; }

.p12_l {
  padding-left: 12px; }

.p12_r {
  padding-right: 12px; }

.p12_t {
  padding-top: 12px; }

.p12_b {
  padding-bottom: 12px; }

.f13 {
  font-size: 13px; }

.m13_all {
  margin: 13px; }

.m13_l {
  margin-left: 13px; }

.m13_r {
  margin-right: 13px; }

.m13_t {
  margin-top: 13px; }

.m13_b {
  margin-bottom: 13px; }

.p13_all {
  padding: 13px; }

.p13_l {
  padding-left: 13px; }

.p13_r {
  padding-right: 13px; }

.p13_t {
  padding-top: 13px; }

.p13_b {
  padding-bottom: 13px; }

.f14 {
  font-size: 14px; }

.m14_all {
  margin: 14px; }

.m14_l {
  margin-left: 14px; }

.m14_r {
  margin-right: 14px; }

.m14_t {
  margin-top: 14px; }

.m14_b {
  margin-bottom: 14px; }

.p14_all {
  padding: 14px; }

.p14_l {
  padding-left: 14px; }

.p14_r {
  padding-right: 14px; }

.p14_t {
  padding-top: 14px; }

.p14_b {
  padding-bottom: 14px; }

.f15 {
  font-size: 15px; }

.m15_all {
  margin: 15px; }

.m15_l {
  margin-left: 15px; }

.m15_r {
  margin-right: 15px; }

.m15_t {
  margin-top: 15px; }

.m15_b {
  margin-bottom: 15px; }

.p15_all {
  padding: 15px; }

.p15_l {
  padding-left: 15px; }

.p15_r {
  padding-right: 15px; }

.p15_t {
  padding-top: 15px; }

.p15_b {
  padding-bottom: 15px; }

.f16 {
  font-size: 16px; }

.m16_all {
  margin: 16px; }

.m16_l {
  margin-left: 16px; }

.m16_r {
  margin-right: 16px; }

.m16_t {
  margin-top: 16px; }

.m16_b {
  margin-bottom: 16px; }

.p16_all {
  padding: 16px; }

.p16_l {
  padding-left: 16px; }

.p16_r {
  padding-right: 16px; }

.p16_t {
  padding-top: 16px; }

.p16_b {
  padding-bottom: 16px; }

.f17 {
  font-size: 17px; }

.m17_all {
  margin: 17px; }

.m17_l {
  margin-left: 17px; }

.m17_r {
  margin-right: 17px; }

.m17_t {
  margin-top: 17px; }

.m17_b {
  margin-bottom: 17px; }

.p17_all {
  padding: 17px; }

.p17_l {
  padding-left: 17px; }

.p17_r {
  padding-right: 17px; }

.p17_t {
  padding-top: 17px; }

.p17_b {
  padding-bottom: 17px; }

.f18 {
  font-size: 18px; }

.m18_all {
  margin: 18px; }

.m18_l {
  margin-left: 18px; }

.m18_r {
  margin-right: 18px; }

.m18_t {
  margin-top: 18px; }

.m18_b {
  margin-bottom: 18px; }

.p18_all {
  padding: 18px; }

.p18_l {
  padding-left: 18px; }

.p18_r {
  padding-right: 18px; }

.p18_t {
  padding-top: 18px; }

.p18_b {
  padding-bottom: 18px; }

.f19 {
  font-size: 19px; }

.m19_all {
  margin: 19px; }

.m19_l {
  margin-left: 19px; }

.m19_r {
  margin-right: 19px; }

.m19_t {
  margin-top: 19px; }

.m19_b {
  margin-bottom: 19px; }

.p19_all {
  padding: 19px; }

.p19_l {
  padding-left: 19px; }

.p19_r {
  padding-right: 19px; }

.p19_t {
  padding-top: 19px; }

.p19_b {
  padding-bottom: 19px; }

.f20 {
  font-size: 20px; }

.m20_all {
  margin: 20px; }

.m20_l {
  margin-left: 20px; }

.m20_r {
  margin-right: 20px; }

.m20_t {
  margin-top: 20px; }

.m20_b {
  margin-bottom: 20px; }

.p20_all {
  padding: 20px; }

.p20_l {
  padding-left: 20px; }

.p20_r {
  padding-right: 20px; }

.p20_t {
  padding-top: 20px; }

.p20_b {
  padding-bottom: 20px; }

.f21 {
  font-size: 21px; }

.m21_all {
  margin: 21px; }

.m21_l {
  margin-left: 21px; }

.m21_r {
  margin-right: 21px; }

.m21_t {
  margin-top: 21px; }

.m21_b {
  margin-bottom: 21px; }

.p21_all {
  padding: 21px; }

.p21_l {
  padding-left: 21px; }

.p21_r {
  padding-right: 21px; }

.p21_t {
  padding-top: 21px; }

.p21_b {
  padding-bottom: 21px; }

.f22 {
  font-size: 22px; }

.m22_all {
  margin: 22px; }

.m22_l {
  margin-left: 22px; }

.m22_r {
  margin-right: 22px; }

.m22_t {
  margin-top: 22px; }

.m22_b {
  margin-bottom: 22px; }

.p22_all {
  padding: 22px; }

.p22_l {
  padding-left: 22px; }

.p22_r {
  padding-right: 22px; }

.p22_t {
  padding-top: 22px; }

.p22_b {
  padding-bottom: 22px; }

.f23 {
  font-size: 23px; }

.m23_all {
  margin: 23px; }

.m23_l {
  margin-left: 23px; }

.m23_r {
  margin-right: 23px; }

.m23_t {
  margin-top: 23px; }

.m23_b {
  margin-bottom: 23px; }

.p23_all {
  padding: 23px; }

.p23_l {
  padding-left: 23px; }

.p23_r {
  padding-right: 23px; }

.p23_t {
  padding-top: 23px; }

.p23_b {
  padding-bottom: 23px; }

.f24 {
  font-size: 24px; }

.m24_all {
  margin: 24px; }

.m24_l {
  margin-left: 24px; }

.m24_r {
  margin-right: 24px; }

.m24_t {
  margin-top: 24px; }

.m24_b {
  margin-bottom: 24px; }

.p24_all {
  padding: 24px; }

.p24_l {
  padding-left: 24px; }

.p24_r {
  padding-right: 24px; }

.p24_t {
  padding-top: 24px; }

.p24_b {
  padding-bottom: 24px; }

.f25 {
  font-size: 25px; }

.m25_all {
  margin: 25px; }

.m25_l {
  margin-left: 25px; }

.m25_r {
  margin-right: 25px; }

.m25_t {
  margin-top: 25px; }

.m25_b {
  margin-bottom: 25px; }

.p25_all {
  padding: 25px; }

.p25_l {
  padding-left: 25px; }

.p25_r {
  padding-right: 25px; }

.p25_t {
  padding-top: 25px; }

.p25_b {
  padding-bottom: 25px; }

.f26 {
  font-size: 26px; }

.m26_all {
  margin: 26px; }

.m26_l {
  margin-left: 26px; }

.m26_r {
  margin-right: 26px; }

.m26_t {
  margin-top: 26px; }

.m26_b {
  margin-bottom: 26px; }

.p26_all {
  padding: 26px; }

.p26_l {
  padding-left: 26px; }

.p26_r {
  padding-right: 26px; }

.p26_t {
  padding-top: 26px; }

.p26_b {
  padding-bottom: 26px; }

.f27 {
  font-size: 27px; }

.m27_all {
  margin: 27px; }

.m27_l {
  margin-left: 27px; }

.m27_r {
  margin-right: 27px; }

.m27_t {
  margin-top: 27px; }

.m27_b {
  margin-bottom: 27px; }

.p27_all {
  padding: 27px; }

.p27_l {
  padding-left: 27px; }

.p27_r {
  padding-right: 27px; }

.p27_t {
  padding-top: 27px; }

.p27_b {
  padding-bottom: 27px; }

.f28 {
  font-size: 28px; }

.m28_all {
  margin: 28px; }

.m28_l {
  margin-left: 28px; }

.m28_r {
  margin-right: 28px; }

.m28_t {
  margin-top: 28px; }

.m28_b {
  margin-bottom: 28px; }

.p28_all {
  padding: 28px; }

.p28_l {
  padding-left: 28px; }

.p28_r {
  padding-right: 28px; }

.p28_t {
  padding-top: 28px; }

.p28_b {
  padding-bottom: 28px; }

.f29 {
  font-size: 29px; }

.m29_all {
  margin: 29px; }

.m29_l {
  margin-left: 29px; }

.m29_r {
  margin-right: 29px; }

.m29_t {
  margin-top: 29px; }

.m29_b {
  margin-bottom: 29px; }

.p29_all {
  padding: 29px; }

.p29_l {
  padding-left: 29px; }

.p29_r {
  padding-right: 29px; }

.p29_t {
  padding-top: 29px; }

.p29_b {
  padding-bottom: 29px; }

.f30 {
  font-size: 30px; }

.m30_all {
  margin: 30px; }

.m30_l {
  margin-left: 30px; }

.m30_r {
  margin-right: 30px; }

.m30_t {
  margin-top: 30px; }

.m30_b {
  margin-bottom: 30px; }

.p30_all {
  padding: 30px; }

.p30_l {
  padding-left: 30px; }

.p30_r {
  padding-right: 30px; }

.p30_t {
  padding-top: 30px; }

.p30_b {
  padding-bottom: 30px; }

.f31 {
  font-size: 31px; }

.m31_all {
  margin: 31px; }

.m31_l {
  margin-left: 31px; }

.m31_r {
  margin-right: 31px; }

.m31_t {
  margin-top: 31px; }

.m31_b {
  margin-bottom: 31px; }

.p31_all {
  padding: 31px; }

.p31_l {
  padding-left: 31px; }

.p31_r {
  padding-right: 31px; }

.p31_t {
  padding-top: 31px; }

.p31_b {
  padding-bottom: 31px; }

.f32 {
  font-size: 32px; }

.m32_all {
  margin: 32px; }

.m32_l {
  margin-left: 32px; }

.m32_r {
  margin-right: 32px; }

.m32_t {
  margin-top: 32px; }

.m32_b {
  margin-bottom: 32px; }

.p32_all {
  padding: 32px; }

.p32_l {
  padding-left: 32px; }

.p32_r {
  padding-right: 32px; }

.p32_t {
  padding-top: 32px; }

.p32_b {
  padding-bottom: 32px; }

.f33 {
  font-size: 33px; }

.m33_all {
  margin: 33px; }

.m33_l {
  margin-left: 33px; }

.m33_r {
  margin-right: 33px; }

.m33_t {
  margin-top: 33px; }

.m33_b {
  margin-bottom: 33px; }

.p33_all {
  padding: 33px; }

.p33_l {
  padding-left: 33px; }

.p33_r {
  padding-right: 33px; }

.p33_t {
  padding-top: 33px; }

.p33_b {
  padding-bottom: 33px; }

.f34 {
  font-size: 34px; }

.m34_all {
  margin: 34px; }

.m34_l {
  margin-left: 34px; }

.m34_r {
  margin-right: 34px; }

.m34_t {
  margin-top: 34px; }

.m34_b {
  margin-bottom: 34px; }

.p34_all {
  padding: 34px; }

.p34_l {
  padding-left: 34px; }

.p34_r {
  padding-right: 34px; }

.p34_t {
  padding-top: 34px; }

.p34_b {
  padding-bottom: 34px; }

.f35 {
  font-size: 35px; }

.m35_all {
  margin: 35px; }

.m35_l {
  margin-left: 35px; }

.m35_r {
  margin-right: 35px; }

.m35_t {
  margin-top: 35px; }

.m35_b {
  margin-bottom: 35px; }

.p35_all {
  padding: 35px; }

.p35_l {
  padding-left: 35px; }

.p35_r {
  padding-right: 35px; }

.p35_t {
  padding-top: 35px; }

.p35_b {
  padding-bottom: 35px; }

.f36 {
  font-size: 36px; }

.m36_all {
  margin: 36px; }

.m36_l {
  margin-left: 36px; }

.m36_r {
  margin-right: 36px; }

.m36_t {
  margin-top: 36px; }

.m36_b {
  margin-bottom: 36px; }

.p36_all {
  padding: 36px; }

.p36_l {
  padding-left: 36px; }

.p36_r {
  padding-right: 36px; }

.p36_t {
  padding-top: 36px; }

.p36_b {
  padding-bottom: 36px; }

.f37 {
  font-size: 37px; }

.m37_all {
  margin: 37px; }

.m37_l {
  margin-left: 37px; }

.m37_r {
  margin-right: 37px; }

.m37_t {
  margin-top: 37px; }

.m37_b {
  margin-bottom: 37px; }

.p37_all {
  padding: 37px; }

.p37_l {
  padding-left: 37px; }

.p37_r {
  padding-right: 37px; }

.p37_t {
  padding-top: 37px; }

.p37_b {
  padding-bottom: 37px; }

.f38 {
  font-size: 38px; }

.m38_all {
  margin: 38px; }

.m38_l {
  margin-left: 38px; }

.m38_r {
  margin-right: 38px; }

.m38_t {
  margin-top: 38px; }

.m38_b {
  margin-bottom: 38px; }

.p38_all {
  padding: 38px; }

.p38_l {
  padding-left: 38px; }

.p38_r {
  padding-right: 38px; }

.p38_t {
  padding-top: 38px; }

.p38_b {
  padding-bottom: 38px; }

.f39 {
  font-size: 39px; }

.m39_all {
  margin: 39px; }

.m39_l {
  margin-left: 39px; }

.m39_r {
  margin-right: 39px; }

.m39_t {
  margin-top: 39px; }

.m39_b {
  margin-bottom: 39px; }

.p39_all {
  padding: 39px; }

.p39_l {
  padding-left: 39px; }

.p39_r {
  padding-right: 39px; }

.p39_t {
  padding-top: 39px; }

.p39_b {
  padding-bottom: 39px; }

.f40 {
  font-size: 40px; }

.m40_all {
  margin: 40px; }

.m40_l {
  margin-left: 40px; }

.m40_r {
  margin-right: 40px; }

.m40_t {
  margin-top: 40px; }

.m40_b {
  margin-bottom: 40px; }

.p40_all {
  padding: 40px; }

.p40_l {
  padding-left: 40px; }

.p40_r {
  padding-right: 40px; }

.p40_t {
  padding-top: 40px; }

.p40_b {
  padding-bottom: 40px; }

.f41 {
  font-size: 41px; }

.m41_all {
  margin: 41px; }

.m41_l {
  margin-left: 41px; }

.m41_r {
  margin-right: 41px; }

.m41_t {
  margin-top: 41px; }

.m41_b {
  margin-bottom: 41px; }

.p41_all {
  padding: 41px; }

.p41_l {
  padding-left: 41px; }

.p41_r {
  padding-right: 41px; }

.p41_t {
  padding-top: 41px; }

.p41_b {
  padding-bottom: 41px; }

.f42 {
  font-size: 42px; }

.m42_all {
  margin: 42px; }

.m42_l {
  margin-left: 42px; }

.m42_r {
  margin-right: 42px; }

.m42_t {
  margin-top: 42px; }

.m42_b {
  margin-bottom: 42px; }

.p42_all {
  padding: 42px; }

.p42_l {
  padding-left: 42px; }

.p42_r {
  padding-right: 42px; }

.p42_t {
  padding-top: 42px; }

.p42_b {
  padding-bottom: 42px; }

.f43 {
  font-size: 43px; }

.m43_all {
  margin: 43px; }

.m43_l {
  margin-left: 43px; }

.m43_r {
  margin-right: 43px; }

.m43_t {
  margin-top: 43px; }

.m43_b {
  margin-bottom: 43px; }

.p43_all {
  padding: 43px; }

.p43_l {
  padding-left: 43px; }

.p43_r {
  padding-right: 43px; }

.p43_t {
  padding-top: 43px; }

.p43_b {
  padding-bottom: 43px; }

.f44 {
  font-size: 44px; }

.m44_all {
  margin: 44px; }

.m44_l {
  margin-left: 44px; }

.m44_r {
  margin-right: 44px; }

.m44_t {
  margin-top: 44px; }

.m44_b {
  margin-bottom: 44px; }

.p44_all {
  padding: 44px; }

.p44_l {
  padding-left: 44px; }

.p44_r {
  padding-right: 44px; }

.p44_t {
  padding-top: 44px; }

.p44_b {
  padding-bottom: 44px; }

.f45 {
  font-size: 45px; }

.m45_all {
  margin: 45px; }

.m45_l {
  margin-left: 45px; }

.m45_r {
  margin-right: 45px; }

.m45_t {
  margin-top: 45px; }

.m45_b {
  margin-bottom: 45px; }

.p45_all {
  padding: 45px; }

.p45_l {
  padding-left: 45px; }

.p45_r {
  padding-right: 45px; }

.p45_t {
  padding-top: 45px; }

.p45_b {
  padding-bottom: 45px; }

.f46 {
  font-size: 46px; }

.m46_all {
  margin: 46px; }

.m46_l {
  margin-left: 46px; }

.m46_r {
  margin-right: 46px; }

.m46_t {
  margin-top: 46px; }

.m46_b {
  margin-bottom: 46px; }

.p46_all {
  padding: 46px; }

.p46_l {
  padding-left: 46px; }

.p46_r {
  padding-right: 46px; }

.p46_t {
  padding-top: 46px; }

.p46_b {
  padding-bottom: 46px; }

.f47 {
  font-size: 47px; }

.m47_all {
  margin: 47px; }

.m47_l {
  margin-left: 47px; }

.m47_r {
  margin-right: 47px; }

.m47_t {
  margin-top: 47px; }

.m47_b {
  margin-bottom: 47px; }

.p47_all {
  padding: 47px; }

.p47_l {
  padding-left: 47px; }

.p47_r {
  padding-right: 47px; }

.p47_t {
  padding-top: 47px; }

.p47_b {
  padding-bottom: 47px; }

.f48 {
  font-size: 48px; }

.m48_all {
  margin: 48px; }

.m48_l {
  margin-left: 48px; }

.m48_r {
  margin-right: 48px; }

.m48_t {
  margin-top: 48px; }

.m48_b {
  margin-bottom: 48px; }

.p48_all {
  padding: 48px; }

.p48_l {
  padding-left: 48px; }

.p48_r {
  padding-right: 48px; }

.p48_t {
  padding-top: 48px; }

.p48_b {
  padding-bottom: 48px; }

.f49 {
  font-size: 49px; }

.m49_all {
  margin: 49px; }

.m49_l {
  margin-left: 49px; }

.m49_r {
  margin-right: 49px; }

.m49_t {
  margin-top: 49px; }

.m49_b {
  margin-bottom: 49px; }

.p49_all {
  padding: 49px; }

.p49_l {
  padding-left: 49px; }

.p49_r {
  padding-right: 49px; }

.p49_t {
  padding-top: 49px; }

.p49_b {
  padding-bottom: 49px; }

.h50 {
  height: 50%; }

.w50 {
  width: 50%; }

.h100 {
  height: 100%; }

.w100 {
  width: 100%; }

.w100vw {
  width: 100vw; }

.h100vh {
  height: 100vh; }

.relative {
  position: relative; }

.absolute {
  position: absolute; }

html,
body {
  font-family: "Roboto Mono", monospace;
  font-size: 14px;
  font-weight: 400;
  background-color: #000; }

label {
  color: aliceblue; }

.green1 {
  color: limegreen; }

.game.__controls__.green .menu {
  background-color: rgba(46, 117, 93, 0.507); }

.game.__controls__.green .menu > .buttons > button,
.game.__controls__.green .betAmountButton {
  background-color: #01472c;
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.3), inset 0 -2px 0px 2px rgba(0, 19, 12, 0.5); }

.game.__controls__.green .menu > .balance {
  background-color: rgba(0, 20, 0, 0.5); }

.game.__controls__.green .menu > .balance > .currency,
.game.__controls__.green .betAmountButton > label.currency {
  color: #c6f8c6; }

.game.__controls__ {
  height: 100%;
  box-sizing: border-box; }
  .game.__controls__.hide {
    display: none; }
  .game.__controls__ .menu {
    box-sizing: border-box;
    background-color: rgba(46, 64, 117, 0.507);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 3px; }
    .game.__controls__ .menu > .buttons {
      box-sizing: border-box;
      display: flex;
      min-width: 317px; }
      @media (max-width: 600px) {
        .game.__controls__ .menu > .buttons {
          flex: 1 1; } }

@-webkit-keyframes load {
  from {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0); }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes load {
  from {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0); }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); } }
      .game.__controls__ .menu > .buttons > button {
        opacity: 0;
        -webkit-transform: scale(0);
                transform: scale(0);
        -webkit-animation-delay: 0.3s;
                animation-delay: 0.3s;
        -webkit-animation-name: load;
                animation-name: load;
        -webkit-animation-duration: 1s;
                animation-duration: 1s;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
        cursor: pointer;
        padding: 3px 8px 7px 8px;
        font-size: 22px;
        outline: none;
        color: #f3efb7;
        background-color: #3657c5;
        border: none;
        margin: 1px;
        box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.3), inset 0 -2px 0px 2px rgba(24, 50, 134, 0.5);
        position: relative;
        overflow: hidden;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        -webkit-filter: brightness(100%);
                filter: brightness(100%); }
        .game.__controls__ .menu > .buttons > button > b {
          display: block; }
        .game.__controls__ .menu > .buttons > button > i > svg,
        .game.__controls__ .menu > .buttons > button > b {
          -webkit-transition: all 0.3s ease;
          transition: all 0.3s ease; }
        @media (max-width: 600px) {
          .game.__controls__ .menu > .buttons > button {
            flex: 1 1;
            margin: 0px;
            padding: 6px 0px 10px 0px;
            font-size: 14px; }
            .game.__controls__ .menu > .buttons > button > i > svg {
              width: 18px;
              height: 18px; } }
        @media (hover: hover) {
          .game.__controls__ .menu > .buttons > button:hover {
            -webkit-filter: brightness(200%);
                    filter: brightness(200%); } }
        .game.__controls__ .menu > .buttons > button:active {
          -webkit-filter: brightness(70%);
                  filter: brightness(70%); }
          .game.__controls__ .menu > .buttons > button:active > i > svg,
          .game.__controls__ .menu > .buttons > button:active > b {
            -webkit-transform: scale(0.8);
                    transform: scale(0.8); }
    .game.__controls__ .menu .balance {
      background-color: rgba(255, 255, 255, 0.1);
      box-sizing: border-box;
      padding: 0 5px;
      margin: 2px 0 0 0;
      font-size: 22px;
      min-width: 120px;
      display: flex;
      align-items: center;
      justify-content: center; }
      @media (max-width: 600px) {
        .game.__controls__ .menu .balance {
          min-width: 316px;
          flex: 1 1;
          margin-top: 3px;
          padding: 3px; } }
      .game.__controls__ .menu .balance > .amount {
        font-weight: bold; }
      .game.__controls__ .menu .balance > .currency {
        font-weight: bold;
        margin-left: 5px;
        color: lightskyblue; }
  .game.__controls__ .betAmountButton {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    width: auto;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
    padding: 10px 10px 13px 10px;
    font-size: 22px;
    outline: none;
    color: #f3efb7;
    background-color: #3657c5;
    border: none;
    margin: 1px;
    box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.3), inset 0 -2px 0px 2px rgba(24, 50, 134, 0.5);
    position: relative;
    overflow: hidden;
    -webkit-transition: -webkit-filter 0.3s ease;
    transition: -webkit-filter 0.3s ease;
    transition: filter 0.3s ease;
    transition: filter 0.3s ease, -webkit-filter 0.3s ease;
    flex-direction: row; }
    .game.__controls__ .betAmountButton > label {
      font-weight: bold;
      cursor: pointer;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
      -webkit-transition: -webkit-filter 0.3s ease;
      transition: -webkit-filter 0.3s ease;
      transition: filter 0.3s ease;
      transition: filter 0.3s ease, -webkit-filter 0.3s ease;
      width: auto;
      margin: 0 0 0 0; }
      .game.__controls__ .betAmountButton > label.currency {
        -webkit-transform-origin: left;
                transform-origin: left;
        margin-left: 5px;
        color: lightskyblue; }
      .game.__controls__ .betAmountButton > label.amount {
        -webkit-transform-origin: right;
                transform-origin: right; }
    @media (hover: hover) {
      .game.__controls__ .betAmountButton:hover {
        -webkit-filter: brightness(200%);
                filter: brightness(200%); } }
    .game.__controls__ .betAmountButton:active {
      -webkit-filter: brightness(70%);
              filter: brightness(70%); }

.animateBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  z-index: 2;
  pointer-events: none;
  /* FF3.6+ */
  background-image: -webkit-gradient(left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(50%, rgba(0, 255, 255, 0.795)), color-stop(70%, rgba(255, 255, 255, 0)));
  /* Chrome, Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE 10+ */
  background-image: -webkit-gradient(linear, left top, right top, color-stop(30%, rgba(255, 255, 255, 0)), color-stop(50%, rgba(0, 255, 255, 0.795)), color-stop(70%, rgba(255, 255, 255, 0)));
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 30%, rgba(0, 255, 255, 0.795) 50%, rgba(255, 255, 255, 0) 70%);
  /* W3C */
  -webkit-animation-name: animateBottom;
          animation-name: animateBottom;
  -webkit-animation-delay: 0ms;
          animation-delay: 0ms;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  background-position: -40%;
  background-size: 200%;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  background-repeat: no-repeat; }

/* Safari 4.0 - 8.0 */
@-webkit-keyframes animateBottom {
  from {
    background-position: -40%; }
  to {
    background-position: 140%; } }

/* Standard syntax */
@keyframes animateBottom {
  from {
    background-position: -40%; }
  to {
    background-position: 140%; } }

.animateLeft {
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 100%;
  z-index: 2;
  pointer-events: none;
  /* FF3.6+ */
  background-image: -webkit-gradient(top left, top right, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(50%, rgba(0, 255, 255, 0.795)), color-stop(70%, rgba(255, 255, 255, 0)));
  /* Chrome, Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE 10+ */
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(30%, rgba(255, 255, 255, 0)), color-stop(50%, rgba(0, 255, 255, 0.795)), color-stop(70%, rgba(255, 255, 255, 0)));
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 30%, rgba(0, 255, 255, 0.795) 50%, rgba(255, 255, 255, 0) 70%);
  /* W3C */
  -webkit-animation-name: animateLeft;
          animation-name: animateLeft;
  -webkit-animation-delay: 750ms;
          animation-delay: 750ms;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  background-position: 100% -40%;
  background-size: 100% 200%;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  background-repeat: no-repeat; }

/* Safari 4.0 - 8.0 */
@-webkit-keyframes animateLeft {
  from {
    background-position: 100% -40%; }
  to {
    background-position: 100% 140%; } }

/* Standard syntax */
@keyframes animateLeft {
  from {
    background-position: 100% -40%; }
  to {
    background-position: 100% 140%; } }

.animateTop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  z-index: 2;
  pointer-events: none;
  /* FF3.6+ */
  background-image: -webkit-gradient(left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(50%, rgba(0, 255, 255, 0.795)), color-stop(70%, rgba(255, 255, 255, 0)));
  /* Chrome, Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE 10+ */
  background-image: -webkit-gradient(linear, left top, right top, color-stop(30%, rgba(255, 255, 255, 0)), color-stop(50%, rgba(0, 255, 255, 0.795)), color-stop(70%, rgba(255, 255, 255, 0)));
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 30%, rgba(0, 255, 255, 0.795) 50%, rgba(255, 255, 255, 0) 70%);
  /* W3C */
  -webkit-animation-name: animateTop;
          animation-name: animateTop;
  -webkit-animation-delay: 1500ms;
          animation-delay: 1500ms;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  background-position: 140%;
  background-size: 200%;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  background-repeat: no-repeat; }

/* Safari 4.0 - 8.0 */
@-webkit-keyframes animateTop {
  from {
    background-position: 140%; }
  to {
    background-position: -40%; } }

/* Standard syntax */
@keyframes animateTop {
  from {
    background-position: 140%; }
  to {
    background-position: -40%; } }

.animateRight {
  position: absolute;
  right: 0;
  top: 0;
  width: 1px;
  height: 100%;
  z-index: 2;
  pointer-events: none;
  /* FF3.6+ */
  background-image: -webkit-gradient(top left, top right, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(50%, rgba(0, 255, 255, 0.795)), color-stop(70%, rgba(255, 255, 255, 0)));
  /* Chrome, Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE 10+ */
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(30%, rgba(255, 255, 255, 0)), color-stop(50%, rgba(0, 255, 255, 0.795)), color-stop(70%, rgba(255, 255, 255, 0)));
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 30%, rgba(0, 255, 255, 0.795) 50%, rgba(255, 255, 255, 0) 70%);
  /* W3C */
  -webkit-animation-name: animateRight;
          animation-name: animateRight;
  -webkit-animation-delay: 2250ms;
          animation-delay: 2250ms;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  background-position: 100% 140%;
  background-size: 100% 200%;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  background-repeat: no-repeat; }

/* Safari 4.0 - 8.0 */
@-webkit-keyframes animateRight {
  from {
    background-position: 100% 140%; }
  to {
    background-position: 100% -40%; } }

/* Standard syntax */
@keyframes animateRight {
  from {
    background-position: 100% 140%; }
  to {
    background-position: 100% -40%; } }

input {
  position: relative;
  padding: 10px;
  border-radius: 5px;
  border: none;
  width: 100%;
  background-color: #1e1d23;
  color: #f5a623;
  box-shadow: inset 0 2px 2px 0.5px rgba(0, 0, 0, 0.5), 0 2px 2px 0.5px #393c43; }
  input:disabled {
    opacity: 0.5;
    pointer-events: none; }

button {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding: 5px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease; }
  button.btnIcon {
    border-radius: 50%;
    background-color: #282b34;
    border: none;
    color: #97eff9;
    width: 32px;
    height: 32px;
    box-shadow: 0 2px 2px 0.5px rgba(0, 0, 0, 0.5), inset 0 1px 3px 0.5px #118ab8; }
    button.btnIcon svg {
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
      opacity: 0.7; }
    @media (hover: hover) {
      button.btnIcon:hover svg {
        opacity: 1;
        -webkit-filter: drop-shadow(0px 0px 5px #118ab8);
                filter: drop-shadow(0px 0px 5px #118ab8); } }
    button.btnIcon:active {
      background-color: #1e1d23;
      box-shadow: 0 2px 2px 0.5px #393c43, inset 0 2px 2px 0.5px rgba(0, 0, 0, 0.5); }
      button.btnIcon:active svg {
        opacity: 1;
        color: #32a7b4;
        -webkit-filter: drop-shadow(0px 0px 0px #118ab8);
                filter: drop-shadow(0px 0px 0px #118ab8); }
    button.btnIcon i[fill="currentColor"] {
      display: flex !important;
      align-items: center;
      justify-content: center; }
  button.btnDefault {
    border-radius: 5px;
    background-color: transparent;
    border: none;
    font-weight: normal;
    color: #f5a623;
    box-shadow: 0 0 1px 3px #111010, inset 0 1px 0px 1px #324b5e;
    -webkit-transition: box-shadow 0.1s ease, color 0.2s ease, text-shadow 0.2s ease, background-color 0.2s ease;
    transition: box-shadow 0.1s ease, color 0.2s ease, text-shadow 0.2s ease, background-color 0.2s ease;
    text-shadow: 0 0px 0px #ffc466; }
    @media (hover: hover) {
      button.btnDefault:hover {
        color: #ffc466;
        text-shadow: 0 0px 5px #ffc466; } }
    button.btnDefault:active, button.btnDefault.on {
      background-color: #1e1d23;
      color: #9b9b9b;
      text-shadow: 0 0px 0px #1e1d23;
      box-shadow: 0 2px 2px 0.5px #393c43, inset 0 2px 2px 0.5px rgba(0, 0, 0, 0.5); }
  button:disabled {
    pointer-events: none;
    opacity: 0.3; }
  button.btnRed {
    background-color: #ff0000;
    color: rgba(255, 255, 255, 0.85);
    box-shadow: 0 0 1px 3px rgba(104, 6, 6, 0.5), inset 0 1px 0px 1px #ff6060, 0 0 10px 5px rgba(255, 0, 0, 0.5); }
    @media (hover: hover) {
      button.btnRed:hover {
        color: white;
        text-shadow: 0 0px 3px white;
        box-shadow: 0 0 1px 3px rgba(104, 6, 6, 0.5), inset 0 1px 0px 1px #ff6060, 0 0 10px 5px rgba(255, 55, 55, 0.7); } }
    button.btnRed:active {
      background-color: #1e1d23;
      color: #da4848;
      text-shadow: 0 0px 0px #da4848;
      box-shadow: 0 2px 2px 0.5px #393c43, inset 0 2px 2px 0.5px rgba(0, 0, 0, 0.5); }

.loaderWrapper {
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  position: absolute;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  pointer-events: none; }
  .loaderWrapper .bounded-wave-loader {
    pointer-events: none;
    background: -webkit-gradient(linear, left bottom, left top, from(dodgerblue), color-stop(10%, dodgerblue), color-stop(10%, rgba(0, 0, 0, 0)));
    background: linear-gradient(to top, dodgerblue 0%, dodgerblue 10%, rgba(0, 0, 0, 0) 10%);
    -webkit-transform: translate(0%, 0%);
            transform: translate(0%, 0%);
    overflow: hidden;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    box-shadow: 0 0 0 4px #333; }
    .loaderWrapper .bounded-wave-loader > label {
      color: dodgerblue;
      font-weight: bold;
      font-size: 12px;
      pointer-events: none;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 3; }
  .loaderWrapper .bounded-wave-loader svg {
    pointer-events: none;
    position: absolute;
    bottom: 0;
    -webkit-animation: shift 1s linear infinite;
            animation: shift 1s linear infinite; }

@-webkit-keyframes shift {
  100% {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); } }

@keyframes shift {
  100% {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); } }

.listScroll {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden; }

.bscroll-indicator {
  cursor: pointer;
  border: none !important;
  background-color: silver !important; }

.toggler {
  min-width: 100px;
  flex: 1 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: #222222;
  height: 45px;
  margin: 0 2px;
  box-sizing: border-box; }
  .toggler.disabled {
    pointer-events: none;
    opacity: 0.5; }
  .toggler > .caption {
    box-sizing: border-box;
    width: 100%;
    text-align: center;
    cursor: pointer;
    display: inline-block;
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease; }
    .toggler > .caption.active {
      color: #f5a623;
      font-size: 18px;
      padding: 3px; }
    .toggler > .caption.pasive {
      color: #b9b9b9 !important;
      font-size: 10px; }

.sprite {
  background-repeat: no-repeat;
  pointer-events: none; }

.container {
  z-index: 1000000;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 10px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center; }
  .container.on {
    opacity: 1; }
  .container.off {
    opacity: 0;
    pointer-events: none; }
  .container > .loaderWrapper {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; }
  .container.loading > .loaderWrapper {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
  .container.loaded > .loaderWrapper {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0; }
  .container > .content {
    position: relative;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background-color: #333;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 320px;
    min-height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top-right-radius: 18px;
    -webkit-transform-origin: 50% 100vh;
            transform-origin: 50% 100vh; }
    .container > .content > .btnClose {
      position: absolute;
      top: 4px;
      right: 4px;
      cursor: pointer !important; }
      .container > .content > .btnClose [fill="currentColor"] {
        display: flex !important;
        align-items: center;
        justify-content: center; }

.fairness {
  position: relative;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 10px;
  width: 100%;
  max-width: 320px;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top-right-radius: 18px;
  -webkit-transform-origin: 50% 100vh;
          transform-origin: 50% 100vh; }
  .fairness > .btnClose {
    position: absolute;
    top: 4px;
    right: 4px;
    cursor: pointer !important; }
    .fairness > .btnClose [fill="currentColor"] {
      display: flex !important;
      align-items: center;
      justify-content: center; }
  .fairness > .inputCsWrapper {
    position: relative;
    width: 100%;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column; }
    .fairness > .inputCsWrapper .divCs {
      width: 100%; }
      .fairness > .inputCsWrapper .divCs input {
        margin-bottom: 5px; }
  .fairness label {
    color: #c5c4c4; }
  .fairness > .labelCs {
    width: 100%;
    text-align: left;
    margin-top: 5px; }
  .fairness .btnRenew {
    position: absolute;
    right: 2.5px;
    top: 2.5px; }
  .fairness .btnRandomize {
    width: 100%; }
  .fairness > .paneWrapper {
    display: flex;
    flex-direction: column; }
    .fairness > .paneWrapper > .pane h5 {
      color: white;
      font-weight: bold;
      margin: 10px 0; }
    .fairness > .paneWrapper > .pane label {
      display: block;
      margin: 3px 0; }
    .fairness > .paneWrapper > .pane .dat {
      color: lightgreen;
      word-break: break-all; }

@media only screen and (max-height: 500px) {
  .fairnessWindow {
    max-width: 750px !important; }
    .fairnessWindow .fairness {
      max-width: 100%; }
      .fairnessWindow .fairness > .inputCsWrapper {
        flex-direction: row; }
        .fairnessWindow .fairness > .inputCsWrapper button {
          margin-left: 5px; }
        .fairnessWindow .fairness > .inputCsWrapper .divCs {
          width: 50%; }
          .fairnessWindow .fairness > .inputCsWrapper .divCs input {
            margin-bottom: 0px; }
      .fairnessWindow .fairness > .paneWrapper {
        display: flex;
        flex-direction: row; }
        .fairnessWindow .fairness > .paneWrapper > .pane {
          width: 50%; }
      .fairnessWindow .fairness .btnRandomize {
        width: 50%;
        margin-left: 10px !important; } }

.betAmount {
  position: relative;
  border-radius: 10px;
  width: 100%;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top-right-radius: 18px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  .betAmount > input {
    margin-bottom: 10px;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    font-size: 28px;
    text-align: center; }
  .betAmount > label {
    margin-bottom: 10px;
    width: 100%; }
  .betAmount > .buttonsPanel {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .betAmount > .buttonsPanel > .btn {
      cursor: pointer !important;
      width: 58px;
      text-transform: uppercase;
      text-align: center;
      padding: 10px;
      margin: 3px;
      border-radius: 5px;
      font-family: "Roboto Mono", monospace;
      font-size: 20px; }
      .betAmount > .buttonsPanel > .btn [fill="currentColor"] {
        display: flex !important;
        align-items: center;
        justify-content: center; }

.betAmountWindow {
  -webkit-transition: all 0.2s ease !important;
  transition: all 0.2s ease !important;
  max-width: 320px !important;
  -webkit-transform-origin: 50% 100vh !important;
          transform-origin: 50% 100vh !important;
  top: calc(50vh - 120px); }

.confirmButtons .btn {
  cursor: pointer !important;
  width: 58px;
  text-transform: uppercase;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  font-family: "Roboto Mono", monospace; }

.labelAllIn {
  padding: 10px; }
  .labelAllIn b {
    color: lime; }

.showMaxConfirm {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  .showMaxConfirm.show {
    visibility: visible;
    height: 68px; }
  .showMaxConfirm.hide {
    visibility: hidden;
    height: 0px;
    pointer-events: none; }

.history {
  position: relative;
  width: 100%; }
  .history .listScroll {
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 5px; }
  .history label {
    margin-bottom: 10px;
    display: block; }
  .history .pairBets {
    margin: 10px 0; }
    .history .pairBets label {
      margin-bottom: 0px; }
    .history .pairBets .dat {
      color: lightgreen; }
  .history .list {
    position: relative;
    height: calc(100vh - 150px); }
  .history .item {
    cursor: pointer;
    width: calc(100% - 15px);
    border: none;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0.1);
    margin-bottom: 5px;
    border-radius: 5px;
    padding: 10px;
    margin: 3px; }
    .history .item .amount {
      color: #d0d0d0; }
    .history .item.win .profit {
      color: lightgreen; }
    .history .item.lose .profit {
      color: lightcoral; }
    .history .item .user {
      color: #f5a623; }
    .history .item .currency {
      text-transform: uppercase;
      color: #f5a623; }
    .history .item .time {
      font-size: 12px;
      color: #d0d0d0; }
      .history .item .time u {
        text-decoration: none;
        color: white; }
    .history .item > .dat {
      cursor: default;
      -webkit-transition: -webkit-transform 0.3s ease;
      transition: -webkit-transform 0.3s ease;
      transition: transform 0.3s ease;
      transition: transform 0.3s ease, -webkit-transform 0.3s ease;
      -webkit-transform: scale(1, 0);
              transform: scale(1, 0);
      -webkit-transform-origin: 50% 0;
              transform-origin: 50% 0;
      max-height: 0px;
      box-shadow: inset 0 0 10px 2px rgba(0, 0, 0, 0.5), 0 0 1px 1px rgba(0, 0, 0, 0.5);
      opacity: 0;
      padding: 0px;
      margin-top: 0px;
      background-color: #333;
      background-image: url("/assets/images/bg4_128x128.png");
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start; }
      .history .item > .dat.visible {
        -webkit-transform: scale(1, 1);
                transform: scale(1, 1);
        max-height: 2000px;
        opacity: 1;
        padding: 5px;
        margin-top: 5px; }
      .history .item > .dat > label {
        margin-top: 5px;
        margin-bottom: 5px;
        text-align: left; }
      .history .item > .dat > .dat {
        text-align: left;
        color: lightgreen; }
      .history .item > .dat > .btnVerify {
        width: 120px;
        margin-top: 10px;
        margin-bottom: 5px;
        margin-left: calc(100% - 125px); }

@media only screen and (max-height: 500px) {
  .historyWindow {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    max-width: 400px !important; } }

.stats {
  width: 100%;
  flex: 1 1;
  position: relative; }
  .stats .btnReset {
    position: absolute;
    bottom: 5px;
    left: 5px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    .stats .btnReset [fill="currentColor"] {
      display: flex !important;
      align-items: center;
      justify-content: center; }
    .stats .btnReset.off {
      -webkit-transform: scale(0, 0);
              transform: scale(0, 0); }
  .stats > .headLabel {
    margin-bottom: 10px;
    display: block; }
  .stats > .tabs {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .stats > .tabs > .tab {
      border: none;
      padding: 10px 3px;
      flex: 1 1;
      box-sizing: border-box;
      text-transform: uppercase;
      cursor: pointer;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
      position: relative; }
  .stats > .panes {
    display: flex;
    flex-direction: column;
    width: 100%; }
    .stats > .panes > .pane {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1 1; }
      .stats > .panes > .pane .luckIcon {
        color: limegreen;
        margin-right: 5px; }
      .stats > .panes > .pane label {
        display: block;
        text-align: center;
        color: #c5c4c4;
        margin-bottom: 5px;
        margin-top: 10px; }
      .stats > .panes > .pane .grey {
        color: #c5c4c4; }
      .stats > .panes > .pane .green {
        color: lightgreen; }
      .stats > .panes > .pane .red {
        color: lightcoral; }
      .stats > .panes > .pane .gold {
        color: #f5a623; }
      .stats > .panes > .pane .white {
        color: aliceblue; }

.statsWindow {
  min-height: 230px !important; }

@media only screen and (max-height: 500px) {
  .stats > .panes {
    flex-direction: row; }
  .statsWindow {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    max-width: 400px !important; } }

.help {
  position: relative;
  width: 100%; }
  .help .frame {
    box-sizing: border-box; }
    .help .frame .slide {
      box-shadow: 0 2px 2px 0.5px #393c43, inset 0 2px 2px 0.5px rgba(0, 0, 0, 0.5);
      background-color: #1e1d23;
      border-radius: 5px;
      padding: 5px;
      box-sizing: border-box;
      border-radius: 5px;
      min-height: 200px; }
      .help .frame .slide img {
        width: 100%;
        -webkit-transform: scale(0.85);
                transform: scale(0.85);
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        border-radius: 5px; }
        .help .frame .slide img.loaded {
          -webkit-transform: scale(1);
                  transform: scale(1); }
    .help .frame .text {
      width: 100%;
      box-shadow: 0 2px 2px 0.5px #393c43, inset 0 2px 2px 0.5px rgba(0, 0, 0, 0.5);
      background-color: #1e1d23;
      border-radius: 5px;
      margin-top: 5px;
      min-height: 80px;
      padding: 10px;
      box-sizing: border-box;
      text-align: left;
      font-size: 13px; }
  .help .listScroll {
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 5px; }
  .help label {
    margin-bottom: 10px;
    display: block; }
  .help .list {
    position: relative;
    height: 466px; }
    .help .list p {
      white-space: pre-wrap;
      box-sizing: border-box;
      padding: 10px; }

@media all and (orientation: landscape) {
  .helpWindow {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    max-width: 400px !important; }
  .isMobile .helpWindow {
    -webkit-transform: scale(0.5) !important;
            transform: scale(0.5) !important; } }

.window {
  z-index: 1000000;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 10px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: auto;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .window.draggable {
    top: 0;
    left: 0;
    bottom: auto;
    right: auto;
    -webkit-transition: none;
    transition: none; }
  .window.on {
    opacity: 1; }
  .window.off {
    opacity: 0;
    pointer-events: none; }
  .window > .loaderWrapper {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    .window > .loaderWrapper.on {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1); }
    .window > .loaderWrapper.off {
      opacity: 0;
      -webkit-transform: scale(0);
              transform: scale(0);
      pointer-events: none; }
  .window > .content {
    position: relative;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background-color: #333;
    background-image: url("/assets/images/bg4_128x128.png");
    background-repeat: repeat;
    box-shadow: 0 1px 1px 2px #111010, inset 0 1px 0px 1px #5a7d97, inset 0 0 70px 35px #201f25, 0 0px 500px 20px #95f4fe;
    color: #fff;
    padding: 15px 20px 20px 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 320px;
    min-height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%; }
    .window > .content.on {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1); }
    .window > .content.off {
      opacity: 0;
      -webkit-transform: scale(0);
              transform: scale(0);
      pointer-events: none; }
    .window > .content.draggable {
      box-shadow: 0 1px 1px 2px #111010, inset 0 1px 0px 1px #5a7d97, inset 0 0 70px 35px #201f25, 0 0px 10px 3px #000000; }
    .window > .content > .windowHeader {
      width: 100%; }
      .window > .content > .windowHeader > .windowTitle {
        margin-bottom: 15px;
        display: block;
        color: #9b9b9b;
        font-size: 18px; }
      .window > .content > .windowHeader > .btnClose {
        z-index: 10000;
        position: absolute;
        top: 7px;
        right: 6px;
        cursor: pointer !important; }
        .window > .content > .windowHeader > .btnClose [fill="currentColor"] {
          display: flex !important;
          align-items: center;
          justify-content: center; }

@media only screen and (max-height: 500px) {
  .window > .content {
    max-width: 100%; } }

.canvas {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  overflow: hidden; }
  .canvas > .canvasWrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center; }

.autoPlayWindow {
  max-width: 700px !important; }
  .autoPlayWindow .list {
    position: relative;
    height: 80vh;
    width: 100%; }
    .autoPlayWindow .list .listScroll {
      padding-top: 10px;
      padding-right: 12px; }
  .autoPlayWindow .autoPlay {
    position: relative;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    border-radius: 10px;
    width: 100%;
    min-height: 120px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border-top-right-radius: 18px;
    -webkit-transform-origin: 50% 100vh;
            transform-origin: 50% 100vh; }
    .autoPlayWindow .autoPlay .hide {
      opacity: 0;
      pointer-events: none; }
    .autoPlayWindow .autoPlay .remove {
      display: none;
      pointer-events: none; }
    .autoPlayWindow .autoPlay .btnAutoRoll {
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
      padding: 20px 10px;
      margin-bottom: 5px; }
    .autoPlayWindow .autoPlay > .btnClose {
      position: absolute;
      top: 4px;
      right: 4px;
      cursor: pointer !important; }
      .autoPlayWindow .autoPlay > .btnClose [fill="currentColor"] {
        display: flex !important;
        align-items: center;
        justify-content: center; }
    .autoPlayWindow .autoPlay label {
      color: #c5c4c4; }
    .autoPlayWindow .autoPlay .speed {
      padding: 5px; }
    .autoPlayWindow .autoPlay .sectionWrapper {
      display: flex;
      flex: 1 1;
      flex-wrap: wrap; }
      .autoPlayWindow .autoPlay .sectionWrapper section {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1 1; }
        .autoPlayWindow .autoPlay .sectionWrapper section.losses, .autoPlayWindow .autoPlay .sectionWrapper section.wins {
          justify-content: flex-start;
          padding: 5px;
          border-radius: 10px;
          margin: 0 5px;
          margin-bottom: 6px; }
          .autoPlayWindow .autoPlay .sectionWrapper section.losses .count,
          .autoPlayWindow .autoPlay .sectionWrapper section.losses .inc, .autoPlayWindow .autoPlay .sectionWrapper section.wins .count,
          .autoPlayWindow .autoPlay .sectionWrapper section.wins .inc {
            width: 150px;
            height: 45px;
            text-align: center;
            margin-left: 5px;
            padding: 3px; }
          .autoPlayWindow .autoPlay .sectionWrapper section.losses .inc, .autoPlayWindow .autoPlay .sectionWrapper section.wins .inc {
            padding-right: 30px; }
        .autoPlayWindow .autoPlay .sectionWrapper section .amountType {
          position: absolute;
          top: 7px;
          right: 5px; }
        .autoPlayWindow .autoPlay .sectionWrapper section.losses {
          box-shadow: inset 0 0 50px rgba(255, 74, 74, 0.5), 0 0 2px 2px #000; }
        .autoPlayWindow .autoPlay .sectionWrapper section.wins {
          box-shadow: inset 0 0 50px rgba(85, 255, 85, 0.5), 0 0 2px 2px #000; }
        .autoPlayWindow .autoPlay .sectionWrapper section.limits {
          padding: 5px; }
          .autoPlayWindow .autoPlay .sectionWrapper section.limits .item {
            margin-bottom: 5px; }
          .autoPlayWindow .autoPlay .sectionWrapper section.limits input {
            width: 150px; }

@media only screen and (max-width: 400px) {
  .autoPlayWindow {
    padding: 10px 5px 5px 5px !important; }
  .autoPlayWindow .autoPlay .sectionWrapper section.limits input {
    width: 120px !important; }
  .autoPlayWindow .autoPlay .sectionWrapper section.wins input, .autoPlayWindow .autoPlay .sectionWrapper section.losses input {
    width: 100px !important; } }

.toastContainerOverride {
  z-index: 10000000 !important; }

.toastOverride {
  border-radius: 5px !important;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.5) !important;
  font-family: "Roboto Mono", monospace !important; }
  .toastOverride.Toastify__toast--warning {
    background: rgba(241, 196, 15, 0.75) !important; }
    .toastOverride.Toastify__toast--warning div,
    .toastOverride.Toastify__toast--warning .Toastify__close-button {
      color: #000 !important;
      font-weight: bold; }

.module {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  overflow: hidden;
  pointer-events: none; }
  .module > .moduleWrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center; }

#rollInfo {
  width: 100%; }

.rollInfoContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  box-sizing: border-box; }
  @media (min-width: 400px) {
    .rollInfoContainer {
      padding: 50px; } }
  .rollInfoContainer > .contentAll {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    background-color: rgba(255, 255, 255, 0.1);
    color: white; }
    .rollInfoContainer > .contentAll.loading {
      align-items: center;
      justify-content: center;
      text-align: center; }
    .rollInfoContainer > .contentAll > h1 {
      font-size: 22px;
      margin: 10px 0; }
    .rollInfoContainer > .contentAll > .data {
      display: flex;
      width: 100%;
      padding: 5px;
      border-radius: 5px;
      background-color: rgba(255, 255, 255, 0.1);
      justify-content: center; }
      .rollInfoContainer > .contentAll > .data.off {
        display: none; }
      .rollInfoContainer > .contentAll > .data.on {
        display: flex; }
    .rollInfoContainer > .contentAll > .generalInfo {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      width: 100%; }
      .rollInfoContainer > .contentAll > .generalInfo > figure {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 5px;
        box-sizing: border-box;
        padding: 5px; }
        .rollInfoContainer > .contentAll > .generalInfo > figure:not(:last-child) {
          margin-bottom: 3px; }
        .rollInfoContainer > .contentAll > .generalInfo > figure > label {
          font-size: 12px; }
        .rollInfoContainer > .contentAll > .generalInfo > figure > b {
          font-size: 18px;
          word-break: break-all; }
        .rollInfoContainer > .contentAll > .generalInfo > figure.red > b {
          color: red; }
        .rollInfoContainer > .contentAll > .generalInfo > figure.green > b {
          color: limegreen; }
        .rollInfoContainer > .contentAll > .generalInfo > figure.f_server_sha_512 > b {
          word-break: break-all; }
        .rollInfoContainer > .contentAll > .generalInfo > figure.f_time > b > .d {
          margin-right: 10px; }

.rollInfoWindow {
  max-width: 700px !important; }

