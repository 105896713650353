.toggler {
	min-width: 100px;
	flex: 1;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	cursor: pointer;
	user-select: none;
	background-color: #222222;
	height: 45px;
	margin: 0 2px;
	box-sizing: border-box;
	&.disabled {
		pointer-events: none;
		opacity: 0.5;
	}
	> .caption {
		box-sizing: border-box;
		width: 100%;
		text-align: center;
		cursor: pointer;
		display: inline-block;
		transition: all 0.15s ease;
		&.active {
			color: #f5a623;
			font-size: 18px;
			padding: 3px;
		}
		&.pasive {
			color: #b9b9b9 !important;
			font-size: 10px;
		}
	}
}
