.betAmount {
  position: relative;
  border-radius: 10px;
  width: 100%;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top-right-radius: 18px;
  transition: all 0.3s ease;
  > input {
    margin-bottom: 10px;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    font-size: 28px;
    text-align: center;
  }
  > label {
    margin-bottom: 10px;
    width: 100%;
  }
  > .buttonsPanel {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > .btn {
      cursor: pointer !important;
      width: 58px;
      text-transform: uppercase;
      text-align: center;
      padding: 10px;
      margin: 3px;
      border-radius: 5px;
      font-family: "Roboto Mono", monospace;
      font-size: 20px;
      [fill="currentColor"] {
        display: flex !important;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.betAmountWindow {
  transition: all 0.2s ease !important;
  max-width: 320px !important;
  transform-origin: 50% 100vh !important;
  top: calc(50vh - 120px);
}
.confirmButtons {
  .btn {
    cursor: pointer !important;
    width: 58px;
    text-transform: uppercase;
    text-align: center;
    padding: 10px;
    // margin: 3px;
    border-radius: 5px;
    font-family: "Roboto Mono", monospace;
  }
}
.labelAllIn {
  padding: 10px;
  b {
    color: lime;
  }
}
.showMaxConfirm {
  transition: all 0.3s ease;
  &.show {
    visibility: visible;
    height: 68px;
  }
  &.hide {
    visibility: hidden;
    height: 0px;
    pointer-events: none;
  }
}
