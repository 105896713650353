.window {
	z-index: 1000000;
	background-color: transparent;
	//background-image: url("/assets/images/bg4_128x128.png");
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	padding: 10px;
	transition: all 0.3s ease;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: auto;
	user-select: none;
	&.draggable {
		top: 0;
		left: 0;
		bottom: auto;
		right: auto;
		transition: none;
	}
	&.on {
		opacity: 1;
	}
	&.off {
		opacity: 0;
		pointer-events: none;
	}

	> .loaderWrapper {
		transition: all 0.3s ease;
		&.on {
			opacity: 1;
			transform: scale(1);
		}
		&.off {
			opacity: 0;
			transform: scale(0);
			pointer-events: none;
		}
	}
	> .content {
		position: relative;
		transition: all 0.3s ease;

		&.on {
			opacity: 1;
			transform: scale(1);
		}
		&.off {
			opacity: 0;
			transform: scale(0);
			pointer-events: none;
		}

		background-color: #333;
		background-image: url("/assets/images/bg4_128x128.png");
		background-repeat: repeat;
		box-shadow: 0 1px 1px 2px #111010, inset 0 1px 0px 1px #5a7d97, inset 0 0 70px 35px #201f25, 0 0px 500px 20px #95f4fe;
		&.draggable {
			box-shadow: 0 1px 1px 2px #111010, inset 0 1px 0px 1px #5a7d97, inset 0 0 70px 35px #201f25, 0 0px 10px 3px #000000;
		}
		color: #fff;
		padding: 15px 20px 20px 20px;
		border-radius: 10px;
		width: 100%;
		max-width: 320px;
		min-height: 120px;
		display: flex;
		flex-direction: column;
		align-items: center;
		//border-top-right-radius: 18px;
		transform-origin: 50% 50%;
		> .windowHeader {
			width: 100%;
			> .windowTitle {
				margin-bottom: 15px;
				display: block;
				color: #9b9b9b;
				font-size: 18px;
			}
			> .btnClose {
				z-index: 10000;
				position: absolute;
				top: 7px;
				right: 6px;
				cursor: pointer !important;
				[fill="currentColor"] {
					display: flex !important;
					align-items: center;
					justify-content: center;
				}
			}
		}
	}
}

@media only screen and (max-height: 500px) {
	.window {
		> .content {
			max-width: 100%;
		}
	}
}
