.fairness {
	position: relative;
	transition: all 0.3s ease;
	border-radius: 10px;
	width: 100%;
	max-width: 320px;
	min-height: 120px;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-top-right-radius: 18px;
	transform-origin: 50% 100vh;
	> .btnClose {
		position: absolute;
		top: 4px;
		right: 4px;
		cursor: pointer !important;
		[fill="currentColor"] {
			display: flex !important;
			align-items: center;
			justify-content: center;
		}
	}
	> .inputCsWrapper {
		position: relative;
		width: 100%;
		margin-bottom: 5px;
		display: flex;
		flex-direction: column;
		.divCs {
			width: 100%;
			input {
				margin-bottom: 5px;
			}
		}
	}
	label {
		// text-transform: uppercase;
		color: #c5c4c4;
	}
	> .labelCs {
		width: 100%;
		text-align: left;
		margin-top: 5px;
	}
	.btnRenew {
		position: absolute;
		right: 2.5px;
		top: 2.5px;
	}
	.btnRandomize {
		width: 100%;
	}
	> .paneWrapper {
		display: flex;
		flex-direction: column;
		> .pane {
			h5 {
				color: white;
				font-weight: bold;
				margin: 10px 0;
			}
			label {
				display: block;
				margin: 3px 0;
			}
			.dat {
				color: lightgreen;
				word-break: break-all;
			}
		}
	}
}

@media only screen and (max-height: 500px) {
	.fairnessWindow {
		max-width: 750px !important;

		.fairness {
			max-width: 100%;
			> .inputCsWrapper {
				flex-direction: row;
				button {
					margin-left: 5px;
				}
				.divCs {
					width: 50%;
					input {
						margin-bottom: 0px;
					}
				}
			}
			> .paneWrapper {
				display: flex;
				flex-direction: row;
				> .pane {
					width: 50%;
				}
			}
			.btnRandomize {
				width: 50%;
				margin-left: 10px !important;
			}
		}
	}
}
