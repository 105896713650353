.game.__controls__.green {
	.menu {
		background-color: rgba(46, 117, 93, 0.507);
	}
	.menu > .buttons > button,
	.betAmountButton {
		background-color: rgb(1, 71, 44);
		box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.3), inset 0 -2px 0px 2px rgba(0, 19, 12, 0.5);
	}
	.menu > .balance {
		background-color: rgba(0, 20, 0, 0.5);
	}
	.menu > .balance > .currency,
	.betAmountButton > label.currency {
		color: rgb(198, 248, 198);
	}
}

.game.__controls__ {
	height: 100%;
	box-sizing: border-box;
	&.hide {
		display: none;
	}
	.menu {
		box-sizing: border-box;
		background-color: rgba(46, 64, 117, 0.507);
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 3px;
		> .buttons {
			box-sizing: border-box;
			display: flex;
			min-width: 317px;
			@media (max-width: 600px) {
				flex: 1;
			}
			@keyframes load {
				from {
					opacity: 0;
					transform: scale(0);
				}
				to {
					opacity: 1;
					transform: scale(1);
				}
			}
			> button {
				opacity: 0;
				transform: scale(0);
				animation-delay: 0.3s;
				animation-name: load;
				animation-duration: 1s;
				animation-fill-mode: forwards;
				user-select: none;
				cursor: pointer;
				padding: 3px 8px 7px 8px;
				font-size: 22px;
				// border-radius: 5px;
				outline: none;
				color: rgb(243, 239, 183);
				background-color: rgb(54, 87, 197);

				border: none;
				margin: 1px;
				box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.3), inset 0 -2px 0px 2px rgba(24, 50, 134, 0.5);
				position: relative;
				overflow: hidden;
				transition: all 0.3s ease;
				filter: brightness(100%);
				> b {
					display: block;
				}
				> i > svg,
				> b {
					transition: all 0.3s ease;
				}
				@media (max-width: 600px) {
					flex: 1;
					margin: 0px;
					padding: 6px 0px 10px 0px;
					font-size: 14px;
					> i > svg {
						width: 18px;
						height: 18px;
					}
				}
				@media (hover: hover) {
					&:hover {
						filter: brightness(200%);
					}
				}
				&:active {
					filter: brightness(70%);
					> i > svg,
					> b {
						transform: scale(0.8);
					}
				}
			}
		}
		.balance {
			background-color: rgba(255, 255, 255, 0.1);
			box-sizing: border-box;
			padding: 0 5px;
			margin: 2px 0 0 0;
			font-size: 22px;
			min-width: 120px;
			display: flex;
			align-items: center;
			justify-content: center;
			@media (max-width: 600px) {
				min-width: 316px;
				flex: 1;
				margin-top: 3px;
				padding: 3px;
			}
			> .amount {
				font-weight: bold;
			}
			> .currency {
				font-weight: bold;
				margin-left: 5px;
				color: lightskyblue;
			}
		}
	}

	.betAmountButton {
		margin: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 0;
		width: auto;
		// opacity: 0;
		// transform: scale(1);
		// animation-delay: 0.3s;
		// animation-name: load;
		// animation-duration: 1s;
		// animation-fill-mode: forwards;
		user-select: none;
		cursor: pointer;
		padding: 10px 10px 13px 10px;

		font-size: 22px;
		// border-radius: 5px;
		outline: none;
		color: rgb(243, 239, 183);
		background-color: rgb(54, 87, 197);

		border: none;
		margin: 1px;
		box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.3), inset 0 -2px 0px 2px rgba(24, 50, 134, 0.5);
		position: relative;
		overflow: hidden;
		transition: filter 0.3s ease;
		flex-direction: row;

		> label {
			font-weight: bold;
			cursor: pointer;
			user-select: none;
			transition: filter 0.3s ease;
			width: auto;
			margin: 0 0 0 0;
			&.currency {
				transform-origin: left;
				margin-left: 5px;
				color: lightskyblue;
			}
			&.amount {
				transform-origin: right;
			}
		}
		@media (hover: hover) {
			&:hover {
				filter: brightness(200%);
			}
		}
		&:active {
			filter: brightness(70%);
		}
	}
}
