.loaderWrapper {
	transition: transform 0.3s ease;
	position: absolute;
	height: 80px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
	pointer-events: none;
	.bounded-wave-loader {
		pointer-events: none;
		> label {
			color: rgba(30, 144, 255, 1);
			font-weight: bold;
			font-size: 12px;

			pointer-events: none;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 3;
		}
		background: linear-gradient(to top, rgba(30, 144, 255, 1) 0%, rgba(30, 144, 255, 1) 10%, rgba(0, 0, 0, 0) 10%);
		transform: translate(0%, 0%);
		overflow: hidden;
		// border: 2px solid transparent;
		border-radius: 50%;
		width: 100px;
		height: 100px;
		box-shadow: 0 0 0 4px #333;
	}
	.bounded-wave-loader svg {
		pointer-events: none;
		position: absolute;
		bottom: 0;
		animation: shift 1s linear infinite;
	}

	@keyframes shift {
		100% {
			transform: translateX(-50%);
		}
	}
}
