$violet: rgba(0, 255, 255, 0.795);
.animateBottom {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 1px;
	z-index: 2;
	pointer-events: none;
	background-image: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 30%, $violet 50%, rgba(255, 255, 255, 0) 70%); /* FF3.6+ */
	background-image: -webkit-gradient(
		left top,
		right top,
		color-stop(0%, rgba(255, 255, 255, 0)),
		color-stop(50%, $violet),
		color-stop(70%, rgba(255, 255, 255, 0))
	); /* Chrome, Safari4+ */
	background-image: -webkit-linear-gradient(left, rgba(197, 105, 105, 0) 30%, $violet 50%, rgba(255, 255, 255, 0) 70%); /* Chrome10+,Safari5.1+ */
	background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0) 30%, $violet 50%, rgba(255, 255, 255, 0) 70%); /* Opera 11.10+ */
	background-image: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 30%, $violet 50%, rgba(255, 255, 255, 0) 70%); /* IE 10+ */
	background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 30%, $violet 50%, rgba(255, 255, 255, 0) 70%); /* W3C */
	animation-name: animateBottom;
	animation-delay: 0ms;
	animation-duration: 3s;
	background-position: -40%;
	background-size: 200%;
	animation-iteration-count: infinite;
	background-repeat: no-repeat;
}
/* Safari 4.0 - 8.0 */
@-webkit-keyframes animateBottom {
	from {
		background-position: -40%;
	}
	to {
		background-position: 140%;
	}
}
/* Standard syntax */
@keyframes animateBottom {
	from {
		background-position: -40%;
	}
	to {
		background-position: 140%;
	}
}
.animateLeft {
	position: absolute;
	left: 0;
	top: 0;
	width: 1px;
	height: 100%;
	z-index: 2;
	pointer-events: none;
	background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 30%, $violet 50%, rgba(255, 255, 255, 0) 70%); /* FF3.6+ */
	background-image: -webkit-gradient(
		top left,
		top right,
		color-stop(0%, rgba(255, 255, 255, 0)),
		color-stop(50%, $violet),
		color-stop(70%, rgba(255, 255, 255, 0))
	); /* Chrome, Safari4+ */
	background-image: -webkit-linear-gradient(top, rgba(197, 105, 105, 0) 30%, $violet 50%, rgba(255, 255, 255, 0) 70%); /* Chrome10+,Safari5.1+ */
	background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0) 30%, $violet 50%, rgba(255, 255, 255, 0) 70%); /* Opera 11.10+ */
	background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 30%, $violet 50%, rgba(255, 255, 255, 0) 70%); /* IE 10+ */
	background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 30%, $violet 50%, rgba(255, 255, 255, 0) 70%); /* W3C */
	animation-name: animateLeft;
	animation-delay: 750ms;
	animation-duration: 3s;
	background-position: 100% -40%;
	background-size: 100% 200%;
	animation-iteration-count: infinite;
	background-repeat: no-repeat;
}
/* Safari 4.0 - 8.0 */
@-webkit-keyframes animateLeft {
	from {
		background-position: 100% -40%;
	}
	to {
		background-position: 100% 140%;
	}
}
/* Standard syntax */
@keyframes animateLeft {
	from {
		background-position: 100% -40%;
	}
	to {
		background-position: 100% 140%;
	}
}

.animateTop {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 1px;
	z-index: 2;
	pointer-events: none;
	background-image: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 30%, $violet 50%, rgba(255, 255, 255, 0) 70%); /* FF3.6+ */
	background-image: -webkit-gradient(
		left top,
		right top,
		color-stop(0%, rgba(255, 255, 255, 0)),
		color-stop(50%, $violet),
		color-stop(70%, rgba(255, 255, 255, 0))
	); /* Chrome, Safari4+ */
	background-image: -webkit-linear-gradient(left, rgba(197, 105, 105, 0) 30%, $violet 50%, rgba(255, 255, 255, 0) 70%); /* Chrome10+,Safari5.1+ */
	background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0) 30%, $violet 50%, rgba(255, 255, 255, 0) 70%); /* Opera 11.10+ */
	background-image: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 30%, $violet 50%, rgba(255, 255, 255, 0) 70%); /* IE 10+ */
	background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 30%, $violet 50%, rgba(255, 255, 255, 0) 70%); /* W3C */
	animation-name: animateTop;
	animation-delay: 1500ms;
	animation-duration: 3s;
	background-position: 140%;
	background-size: 200%;
	animation-iteration-count: infinite;
	background-repeat: no-repeat;
}
/* Safari 4.0 - 8.0 */
@-webkit-keyframes animateTop {
	from {
		background-position: 140%;
	}
	to {
		background-position: -40%;
	}
}
/* Standard syntax */
@keyframes animateTop {
	from {
		background-position: 140%;
	}
	to {
		background-position: -40%;
	}
}
.animateRight {
	position: absolute;
	right: 0;
	top: 0;
	width: 1px;
	height: 100%;
	z-index: 2;
	pointer-events: none;
	background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 30%, $violet 50%, rgba(255, 255, 255, 0) 70%); /* FF3.6+ */
	background-image: -webkit-gradient(
		top left,
		top right,
		color-stop(0%, rgba(255, 255, 255, 0)),
		color-stop(50%, $violet),
		color-stop(70%, rgba(255, 255, 255, 0))
	); /* Chrome, Safari4+ */
	background-image: -webkit-linear-gradient(top, rgba(197, 105, 105, 0) 30%, $violet 50%, rgba(255, 255, 255, 0) 70%); /* Chrome10+,Safari5.1+ */
	background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0) 30%, $violet 50%, rgba(255, 255, 255, 0) 70%); /* Opera 11.10+ */
	background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 30%, $violet 50%, rgba(255, 255, 255, 0) 70%); /* IE 10+ */
	background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 30%, $violet 50%, rgba(255, 255, 255, 0) 70%); /* W3C */
	animation-name: animateRight;
	animation-delay: 2250ms;
	animation-duration: 3s;
	background-position: 100% 140%;
	background-size: 100% 200%;
	animation-iteration-count: infinite;
	background-repeat: no-repeat;
}
/* Safari 4.0 - 8.0 */
@-webkit-keyframes animateRight {
	from {
		background-position: 100% 140%;
	}
	to {
		background-position: 100% -40%;
	}
}
/* Standard syntax */
@keyframes animateRight {
	from {
		background-position: 100% 140%;
	}
	to {
		background-position: 100% -40%;
	}
}
