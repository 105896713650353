.container {
	z-index: 1000000;
	background-color: rgba(255, 255, 255, 0.5);
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	padding: 10px;
	transition: all 0.3s ease;
	display: flex;
	align-items: center;
	justify-content: center;
	&.on {
		opacity: 1;
	}
	&.off {
		opacity: 0;
		pointer-events: none;
	}
	> .loaderWrapper {
		transition: all 0.3s ease;
	}
	&.loading {
		> .loaderWrapper {
			transform: scale(1);
			opacity: 1;
		}
	}
	&.loaded {
		> .loaderWrapper {
			transform: scale(0);
			opacity: 0;
		}
	}
	> .content {
		position: relative;
		transition: all 0.3s ease;

		background-color: #333;
		padding: 20px;
		border-radius: 10px;
		width: 100%;
		max-width: 320px;
		min-height: 120px;
		display: flex;
		flex-direction: column;
		align-items: center;
		border-top-right-radius: 18px;
		transform-origin: 50% 100vh;
		> .btnClose {
			position: absolute;
			top: 4px;
			right: 4px;
			cursor: pointer !important;
			[fill="currentColor"] {
				display: flex !important;
				align-items: center;
				justify-content: center;
			}
		}
	}
}
