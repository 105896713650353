.help {
	position: relative;

	width: 100%;
	.frame {
		box-sizing: border-box;
		.slide {
			// border: 2px solid rgba(255, 255, 255, 0.1);
			box-shadow: 0 2px 2px 0.5px #393c43, inset 0 2px 2px 0.5px rgba(0, 0, 0, 0.5);
			background-color: #1e1d23;
			border-radius: 5px;
			padding: 5px;
			box-sizing: border-box;
			border-radius: 5px;
			min-height: 200px;
			img {
				width: 100%;
				transform: scale(0.85);
				transition: all 0.3s ease;
				border-radius: 5px;
				&.loaded {
					transform: scale(1);
				}
			}
		}
		.text {
			width: 100%;
			box-shadow: 0 2px 2px 0.5px #393c43, inset 0 2px 2px 0.5px rgba(0, 0, 0, 0.5);
			background-color: #1e1d23;
			border-radius: 5px;
			margin-top: 5px;
			min-height: 80px;
			padding: 10px;
			box-sizing: border-box;
			text-align: left;
			font-size: 13px;
		}
	}
	.listScroll {
		background-color: rgba(255, 255, 255, 0.05);
		border-radius: 5px;
	}
	label {
		margin-bottom: 10px;
		display: block;
	}
	.list {
		position: relative;
		height: 466px;
		p {
			white-space: pre-wrap;
			box-sizing: border-box;
			padding: 10px;
		}
	}
}

.helpWindow {
}

//orientation: landscape
@media all and (orientation: landscape) {
	// @media only screen and (max-height: 500px) {
	.help {
	}

	.helpWindow {
		transition: all 0.3s ease;
		max-width: 400px !important;
	}
	.isMobile .helpWindow {
		transform: scale(0.5) !important;
	}
}
