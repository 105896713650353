.canvas {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	box-sizing: border-box;
	overflow: hidden;
	> .canvasWrapper {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
