.autoPlayWindow {
	max-width: 700px !important;
	// max-height: 100% !important;
	.list {
		position: relative;
		height: 80vh;
		width: 100%;
		.listScroll {
			padding-top: 10px;
			padding-right: 12px;
		}
	}
	.autoPlay {
		.hide {
			opacity: 0;
			pointer-events: none;
		}
		.remove {
			display: none;
			pointer-events: none;
		}
		position: relative;
		transition: all 0.3s ease;
		border-radius: 10px;
		width: 100%;

		min-height: 120px;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		border-top-right-radius: 18px;
		transform-origin: 50% 100vh;
		.btnAutoRoll {
			user-select: none;
      padding: 20px 10px;
      margin-bottom: 5px;
		}
		> .btnClose {
			position: absolute;
			top: 4px;
			right: 4px;
			cursor: pointer !important;
			[fill="currentColor"] {
				display: flex !important;
				align-items: center;
				justify-content: center;
			}
		}
		label {
			color: #c5c4c4;
		}
		.speed {
			padding: 5px;
		}
		.sectionWrapper {
			display: flex;
			flex: 1;
			flex-wrap: wrap;
			section {
				position: relative;
				display: flex;
				flex-direction: column;
				flex: 1;
				&.losses,
				&.wins {
					justify-content: flex-start;
					// min-width: 300px;
					.count,
					.inc {
						width: 150px;
						height: 45px;
						text-align: center;
						margin-left: 5px;
						padding: 3px;
					}
					.inc {
						padding-right: 30px;
					}
					padding: 5px;
					border-radius: 10px;
					margin: 0 5px;
					margin-bottom: 6px;
				}
				.amountType {
					position: absolute;
					top: 7px;
					right: 5px;
				}
				&.losses {
					box-shadow: inset 0 0 50px rgba(255, 74, 74, 0.5), 0 0 2px 2px #000;
				}
				&.wins {
					box-shadow: inset 0 0 50px rgba(85, 255, 85, 0.5), 0 0 2px 2px #000;
				}
				&.limits {
					padding: 5px;
					.item {
						margin-bottom: 5px;
					}
					input {
						width: 150px;
					}
				}
				// &.losses {
				// 	background-color: aquamarine;
				// }
				// &.wins {
				// 	background-color: antiquewhite;
				// }
				// &.limits {
				// 	background-color: steelblue;
				// }
			}
		}
	}
}
// @media only screen and (min-height: 500px) {
// 	.autoPlayWindow {
//         max-width: 750px !important;
//     }
// }
@media only screen and (max-width: 400px) {
	.autoPlayWindow {
		padding: 10px 5px 5px 5px !important;
	}
	.autoPlayWindow .autoPlay .sectionWrapper {
		section {
			// transform: scale(0.8);
			&.limits {
				// input {
				// 	padding: 3px !important;
				// }
				input {
					width: 120px !important;
				}
			}
			&.wins,
			&.losses {
				input {
					width: 100px !important;
				}
			}
		}

		// section {
		// 	&.wins {
		// 	}
		// }
	}
}
