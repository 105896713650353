#rollInfo {
	width: 100%;
}
.rollInfoContainer {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	@media (min-width: 400px) {
		padding: 50px;
	}
	box-sizing: border-box;
	//min-width: 300px;
	> .contentAll {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 5px;
		box-sizing: border-box;
		width: 100%;
		height: 100%;
		position: relative;
		display: flex;
		background-color: rgba(255, 255, 255, 0.1);
		color: white;
		&.loading {
			align-items: center;
			justify-content: center;
			text-align: center;
		}
		> h1 {
			font-size: 22px;
			margin: 10px 0;
		}
		> .data {
			display: flex;
			width: 100%;
			padding: 5px;
			border-radius: 5px;
			background-color: rgba(255, 255, 255, 0.1);
			justify-content: center;
			&.off {
				display: none;
			}
			&.on {
				display: flex;
			}
		}
		> .generalInfo {
			margin-top: 10px;
			display: flex;
			flex-direction: column;
			width: 100%;
			> figure {
				display: flex;
				flex-direction: column;
				width: 100%;
				&:not(:last-child) {
					margin-bottom: 3px;
				}
				background-color: rgba(255, 255, 255, 0.1);
				border-radius: 5px;
				box-sizing: border-box;
				padding: 5px;
				> label {
					font-size: 12px;
				}
				> b {
					font-size: 18px;
					word-break: break-all;
				}
				&.red > b {
					color: $red1;
				}
				&.green > b {
					color: $green1;
				}
				&.f_server_sha_512 > b {
					word-break: break-all;
				}
				&.f_time > b > .d {
					margin-right: 10px;
				}
			}
		}
	}
}

.rollInfoWindow {
	//position: relative;
	max-width: 700px !important;
}
