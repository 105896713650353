.history {
	position: relative;

	width: 100%;
	.listScroll {
		background-color: rgba(255, 255, 255, 0.05);
		border-radius: 5px;
	}
	label {
		margin-bottom: 10px;
		display: block;
	}
	.pairBets {
		margin: 10px 0;
		label {
			margin-bottom: 0px;
		}
		.dat {
			color: lightgreen;
		}
	}
	.list {
		position: relative;
		height: calc(100vh - 150px);
	}
	.item {
		cursor: pointer;
		width: calc(100% - 15px);
		border: none;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		justify-content: space-between;
		background-color: rgba(255, 255, 255, 0.1);
		margin-bottom: 5px;
		border-radius: 5px;
		padding: 10px;
		margin: 3px;
		.amount {
			color: #d0d0d0;
		}
		&.win {
			.profit {
				color: lightgreen;
			}
		}
		&.lose {
			.profit {
				color: lightcoral;
			}
		}
		.user {
			color: #f5a623;
		}
		.currency {
			text-transform: uppercase;
			color: #f5a623;
		}
		.time {
			font-size: 12px;
			color: #d0d0d0;
			u {
				text-decoration: none;
				color: white;
			}
		}
		> .dat {
			cursor: default;
			transition: transform 0.3s ease;
			transform: scale(1, 0);
			transform-origin: 50% 0;
			max-height: 0px;
			box-shadow: inset 0 0 10px 2px rgba(0, 0, 0, 0.5), 0 0 1px 1px rgba(0, 0, 0, 0.5);
			opacity: 0;
			padding: 0px;
			margin-top: 0px;
			&.visible {
				transform: scale(1, 1);
				// min-height: 200px;
				max-height: 2000px;
				opacity: 1;
				padding: 5px;
				margin-top: 5px;
			}
			background-color: #333;
			background-image: url("/assets/images/bg4_128x128.png");
			border-radius: 5px;

			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			> label {
				margin-top: 5px;
				margin-bottom: 5px;
				text-align: left;
			}
			> .dat {
				text-align: left;
				color: lightgreen;
			}
			> .btnVerify {
				width: 120px;
				margin-top: 10px;
				margin-bottom: 5px;
				margin-left: calc(100% - 125px);
			}
			// > a {
			// 	color: cyan;
			// 	&:hover {
			// 		text-decoration: underline;
			// 	}
			// }
		}
	}
}

.historyWindow {
}

//orientation: landscape
@media only screen and (max-height: 500px) {
	.history {
	}

	.historyWindow {
		transition: all 0.3s ease;
		max-width: 400px !important;
	}
}
