.stats {
	width: 100%;
	flex: 1;
	position: relative;
	.btnReset {
		[fill="currentColor"] {
			display: flex !important;
			align-items: center;
			justify-content: center;
		}
		position: absolute;
		bottom: 5px;
		left: 5px;
		transition: all 0.3s ease;
		&.off {
			transform: scale(0, 0);
		}
	}
	> .headLabel {
		margin-bottom: 10px;
		display: block;
	}
	> .tabs {
		display: flex;
		flex-direction: row;
		align-items: center;
		> .tab {
			border: none;
			padding: 10px 3px;
			flex: 1;
			box-sizing: border-box;
			text-transform: uppercase;
			cursor: pointer;
			user-select: none;
			position: relative;
		}
	}
	> .panes {
		display: flex;
		flex-direction: column;
		width: 100%;
		> .pane {
			display: flex;
			flex-direction: column;
			align-items: center;
			//justify-content: center;
			flex: 1;
			.luckIcon {
				color: limegreen;
				margin-right: 5px;
			}
			label {
				display: block;
				text-align: center;
				color: #c5c4c4;
				margin-bottom: 5px;
				margin-top: 10px;
			}
			.grey {
				color: #c5c4c4;
			}
			.green {
				color: lightgreen;
			}
			.red {
				color: lightcoral;
			}
			.gold {
				color: #f5a623;
			}
			.white {
				color: aliceblue;
			}
		}
	}
}

.statsWindow {
	min-height: 230px !important;
}

@media only screen and (max-height: 500px) {
	.stats {
		> .panes {
			flex-direction: row;
		}
	}

	.statsWindow {
		transition: all 0.3s ease;
		max-width: 400px !important;
	}
}

// @media (hover: hover) {
// 	.va:hover {
// 		background-color: lightcoral;
// 	}
// }
// @media (hover: none) {
// 	.va:active {
// 		background-color: lightgreen;
// 	}
// }
