button {
	user-select: none;
	padding: 5px;
	cursor: pointer;
	transition: all 0.2s ease;
	&.btnIcon {
		border-radius: 50%;
		background-color: #282b34;
		border: none;
		color: #97eff9;
		width: 32px;
		height: 32px;
		box-shadow: 0 2px 2px 0.5px rgba(0, 0, 0, 0.5), inset 0 1px 3px 0.5px #118ab8;
		svg {
			transition: all 0.2s ease;
			opacity: 0.7;
		}
		@media (hover: hover) {
			&:hover {
				svg {
					opacity: 1;
					filter: drop-shadow(0px 0px 5px #118ab8);
				}
			}
		}
		&:active {
			background-color: #1e1d23;
			box-shadow: 0 2px 2px 0.5px rgba(57, 60, 67, 1), inset 0 2px 2px 0.5px rgba(0, 0, 0, 0.5);
			svg {
				opacity: 1;
				color: #32a7b4;
				filter: drop-shadow(0px 0px 0px #118ab8);
			}
		}
		i[fill="currentColor"] {
			display: flex !important;
			align-items: center;
			justify-content: center;
		}
	}
	&.btnDefault {
		border-radius: 5px;
		background-color: transparent;
		border: none;
		font-weight: normal;
		color: #f5a623;
		box-shadow: 0 0 1px 3px #111010, inset 0 1px 0px 1px #324b5e;
		transition: box-shadow 0.1s ease, color 0.2s ease, text-shadow 0.2s ease, background-color 0.2s ease;
		text-shadow: 0 0px 0px #ffc466;
		@media (hover: hover) {
			&:hover {
				color: #ffc466;
				text-shadow: 0 0px 5px #ffc466;
			}
		}
		&:active,
		&.on {
			background-color: #1e1d23;
			color: #9b9b9b;
			text-shadow: 0 0px 0px #1e1d23;
			box-shadow: 0 2px 2px 0.5px rgba(57, 60, 67, 1), inset 0 2px 2px 0.5px rgba(0, 0, 0, 0.5);
		}
	}
	&:disabled {
		pointer-events: none;
		opacity: 0.3;
	}
	&.btnRed {
		background-color: #ff0000;
		color: rgba(255, 255, 255, 0.85);
		box-shadow: 0 0 1px 3px rgba(104, 6, 6, 0.5), inset 0 1px 0px 1px #ff6060, 0 0 10px 5px rgba(255, 0, 0, 0.5);
		@media (hover: hover) {
			&:hover {
				color: rgba(255, 255, 255, 1);
				text-shadow: 0 0px 3px rgba(255, 255, 255, 1);
				box-shadow: 0 0 1px 3px rgba(104, 6, 6, 0.5), inset 0 1px 0px 1px #ff6060, 0 0 10px 5px rgba(255, 55, 55, 0.7);
			}
		}
		&:active {
			background-color: #1e1d23;
			color: #da4848;
			text-shadow: 0 0px 0px #da4848;
			box-shadow: 0 2px 2px 0.5px rgba(57, 60, 67, 1), inset 0 2px 2px 0.5px rgba(0, 0, 0, 0.5);
		}
	}
}
