.listScroll {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
	> ul {
		> li {
			// height: 30px;
			// border-bottom: 1px solid rgba(255, 255, 255, 0.1);
		}
	}
}
.bscroll-indicator {
	cursor: pointer;
	border: none !important;
	background-color: silver !important;
}
